.background{
    height: 100%;
    width: 100%;
}

.user-admin-frame {
    background-color: white;
    border-radius: 10px;
    opacity: 1;
    margin: 0px;
    padding-bottom: 36px;
    min-width: fit-content;
}

.clientAccounts{
    float: right;
    width: 290px;
    padding-top: 20px;
    margin-right: 44px;
}

.searchZone{
    margin-top: 16px;
    margin-left: 36px;
    margin-right: 36px;
}

.filterSearch {
    float: left;
    height: 34px;
    width: 236px;
    margin-right: 10px;
    padding-left: 16px;
    border: 1px solid #E9E9E9;
    border-radius: 2px;
    text-align: left;
    font: normal normal 300 16px/22px Myriad Pro;
    letter-spacing: 0px;
    color: #B2B2B2;
    opacity: 1;
}

.searchButton{
    float: right;
    margin-left: 10px;
}

.actionButton{
    text-align: left;
    width: 120px;
}

.sortPicker{
    float: left;
    margin-right: 10px;
}

.tableContent{
    margin-top: 35px;
    margin-left: 36px;
    margin-right: 36px;
}

.MuiFormControl-root{
    width: inherit;
}
th.MuiFormControl-root{
    text-align: left;
    font: normal normal normal 16px/22px Myriad Pro;
    letter-spacing: 0px;
    color: #A39CA7;
    opacity: 1;
}

header.MuiAppBar-colorPrimary
{
    background-color: #FFF;
}

header.MuiAppBar-positionStatic
{
    height: 50px;
}

header.MuiPaper-elevation4
{
    box-shadow: none;
    border-bottom: #e9e9e9 solid;
}

button.MuiTab-root{
    text-align: left;
    font-size: 16px;
    font-family: "Myriad Pro";
    letter-spacing: 0px;
    color: #352D2B;
    opacity: 1;
    text-transform: none;
}

button.MuiTab-textColorInherit.Mui-selected
{
    color:#18D0CC;
}

span.PrivateTabIndicator-colorSecondary-4{
    background-color:#18D0CC;
}

label.MuiInputLabel-formControl {
    margin-top: 0;
    transform: none;
}

.custom-checkbox {

    display: grid;
    grid-template-areas: "custom_checkbox_control";
    > * {
        grid-area: custom_checkbox_control;
    }

    input {
        opacity: 0;
        width: 24px;
        height: 24px;
    }

    .custom_checkbox_control {
        i{
            background-color: white;
            visibility: hidden;
        }
        border: 1px solid #D0D5DC;
        display: inline-grid;
        align-content: center;
        cursor: pointer;
    }

    .input-checkbox:checked + .custom_checkbox_control i {
        background-color: #31D72F;
        visibility:visible;
        color: white;
        font-weight: bold;
        cursor: pointer;
    }
}

table {
    border-collapse: collapse;
    width: 100%;
}

thead {
    background: #E9E9E9 0% 0% no-repeat padding-box;
    border: 1px solid #E9E9E9;
    height: 52px;
    display: table;
    width: 100%;
}

tbody{
    display: table;
    width: 100%;
}


.headerCell {
    background: #E9E9E9 0% 0% no-repeat padding-box;
    color: #A39CA7;
}

.tableCell {
    opacity: 1;
    padding: 6px 24px 6px 16px;
    text-align: left;
    font: normal normal normal 16px/22px Myriad Pro;
    letter-spacing: 0px;
    width: 18%
}

.tableRow{
    border: 1px solid #E9E9E9;
    opacity: 1;
    height: 40px;
}

.checkboxCell {
    width: 24px;
}

.hidden {
    visibility: hidden;
}

